import { getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';
import {
    format,
    getPayloadObject,
    getTelemetryAttributes,
    ITelemetryContent,
    onTelemetryClick
} from '@msdyn365-commerce-modules/utilities';
// import { RatingComponent } from '@msdyn365-commerce/components';
// import { PriceComponent, RatingComponent } from '@msdyn365-commerce/components';
import { AddToCartBehavior, PriceComponent } from '@msdyn365-commerce/components';
import MsDyn365, {
    IComponent,
    IComponentProps,
    ICoreContext,
    IGridSettings,
    IImageData,
    IImageSettings,
    Image,
    IRequestContext,
    IImageProps,
    getCatalogId,
    getUrlSync
} from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import {
    // ProductAvailableQuantity,
    ProductPrice,
    ProductSearchResult,
    SimpleProduct
} from '@msdyn365-commerce/retail-proxy';
// import { addCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
// import { ErrorNotification, NotificationsManager } from '@msdyn365-commerce-modules/notifications-core';

import { getEstimatedAvailabilityAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import * as React from 'react';
import { IPopupProps, Popup } from '../../utilities/popup';

export interface IProductComponentProps extends IComponentProps<{ product?: ProductSearchResult }> {
    className?: string;
    imageSettings?: IImageSettings;
    savingsText?: string;
    freePriceText?: string;
    originalPriceText?: string;
    currentPriceText?: string;
    ratingAriaLabel?: string;
    allowBack?: boolean;
    telemetryContent?: ITelemetryContent;
    dialogStrings?: {
        goToCartText: string;
        continueShoppingText: string;
        headerItemOneText: string;
        headerItemFormatText: string;
        headerMessageText: string;
        freePriceText: string;
        originalPriceText: string;
        currentPriceText: string;
    };
}

export interface IProductComponent extends IComponent<IProductComponentProps> {}

const PREVIOUSProductCard: React.FC<IProductComponentProps> = ({
    data,
    context,
    imageSettings,
    savingsText,
    freePriceText,
    originalPriceText,
    currentPriceText,
    ratingAriaLabel,
    allowBack,
    typeName,
    id,
    telemetryContent
}) => {
    const product = data.product;
    if (!product) {
        return null;
    }

    let productUrl = product ? getProductPageUrlSync(product.Name ?? '', product.RecordId, context.actionContext, undefined) : '';
    if (allowBack && productUrl) {
        productUrl = updateProductUrl(productUrl, context, 'back=true');
    }

    // Construct telemetry attribute to render
    const payLoad = getPayloadObject('click', telemetryContent!, '', product.RecordId.toString());

    const attribute = getTelemetryAttributes(telemetryContent!, payLoad);

    const valuemin = 0;
    const valuemax = 1000;

    // MODAL
    const [modalOpen, setModalOpen] = React.useState(false);

    const popupProps: IPopupProps = {
        context: context,
        className: 'msc-add-to-pmcart',
        id: id,
        typeName: typeName,
        data: { product: product, price: product.Price },
        // @ts-ignore
        dialogStrings: {},
        imageSettings: imageSettings,
        gridSettings: context.request.gridSettings,
        productQuantity: 1,
        navigationUrl: '',
        modalOpen: modalOpen,
        setModalOpen: setModalOpen
    };

    const renderModalPopup = <Popup {...popupProps} />;

    const catPage = context.request.urlTokens.categories !== undefined ? context.request.urlTokens.categories[0] : '';

    function getPageTemplate() {
        const pageName = context.request.telemetryPageName ?? '';
        /*
       ** Home
       ** Blog
       ** Search Results
       Landing Page
       ** Listing
       Sales
       ** PDP
       ** Cart
       ** Wishlist
       ** MyAccount
       ** Checkout
       ** TYP (order confirmation)
        */

        if (pageName === 'Homepage') {
            var page = 'Home';
        } else if (pageName.includes('Category') || pageName.includes('Categoria')) {
            var page = 'Listing';
        } else if (pageName.includes('sign-in')) {
            var page = 'Login';
            // } else if (pageName.includes("Categoria")) {
            //     var page = 'PLP';
        } else if (pageName.toLowerCase().startsWith('search')) {
            var page = 'Search Results';
        } else if (document.getElementsByClassName('ms-buybox').length === 1) {
            var page = 'PDP';
        } else if (pageName.includes('CartPage')) {
            var page = 'Cart';
        } else if (pageName.includes('CheckoutPage')) {
            var page = 'Checkout';
        } else if (pageName.includes('myaccount')) {
            var page = 'MyAccount';
        } else if (pageName.includes('Wishlist')) {
            var page = 'Wishlist';
        } else if (document.querySelectorAll('[data-m-t="order-confirmation"]').length > 0) {
            var page = 'TYP';
        } else if (pageName === 'Blog' || document.querySelectorAll('a.ms-breadcrumb_link[aria-label="Blog"]').length > 0) {
            var page = 'Blog';
        } else {
            var page = 'Other';
        }
        return page;
    }

    const [available, setAvailable] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [availableLabel, setAvailableLabel] = React.useState('Available');
    const _isAvailable = async () => {
        await getEstimatedAvailabilityAsync(
            { callerContext: context.actionContext },
            { ProductIds: [product.RecordId], DefaultWarehouseOnly: true, QuantityUnitTypeValue: 2 }
        )
            .then(productAvailabilites => {
                if (
                    productAvailabilites &&
                    productAvailabilites.ProductWarehouseInventoryAvailabilities &&
                    productAvailabilites.ProductWarehouseInventoryAvailabilities?.length > 0
                ) {
                    if (product.ItemId !== '99991') {
                        if (
                            (productAvailabilites.ProductWarehouseInventoryAvailabilities[0].MaximumPurchasablePhysicalAvailableQuantity ||
                                0) <= 0
                        ) {
                            setAvailable(false);
                            setLoading(false);
                            setAvailableLabel(
                                productAvailabilites.ProductWarehouseInventoryAvailabilities[0].PhysicalAvailableInventoryLevelLabel ||
                                    'OUT OF STOCK'
                            );
                        } else if (
                            (productAvailabilites.ProductWarehouseInventoryAvailabilities[0].MaximumPurchasablePhysicalAvailableQuantity ||
                                0) >= 1
                        ) {
                            setAvailable(true);
                            setLoading(false);
                        } else if (
                            (productAvailabilites.ProductWarehouseInventoryAvailabilities[0].MaximumPurchasablePhysicalAvailableQuantity ||
                                0) > 0 &&
                            (productAvailabilites.ProductWarehouseInventoryAvailabilities[0].MaximumPurchasablePhysicalAvailableQuantity ||
                                0) < 1
                        ) {
                            // To cover the scenario where Maximum[...]Quantity is 0.5 or so
                            setAvailable(false);
                            setLoading(false);
                            // Without this it would say "Available", which it technically is but it also can't be bought
                            switch (context.request.locale) {
                                case 'it':
                                    setAvailableLabel('NON DISPONIBILE');
                                    break;
                                case 'en-GB':
                                    setAvailableLabel('OUT OF STOCK');
                                    break;
                                case 'en-US':
                                    setAvailableLabel('OUT OF STOCK');
                                    break;
                                case 'de':
                                    setAvailableLabel('NICHT VERFÜGBAR');
                                    break;
                                case 'fr':
                                    setAvailableLabel('NON DISPONIBLE');
                                    break;
                                default:
                                    setAvailableLabel('OUT OF STOCK');
                                    break;
                            }
                        }
                    }
                } else {
                    setAvailable(false);
                    // Without this it would say "Available", which it technically is but it also can't be bought
                    switch (context.request.locale) {
                        case 'it':
                            setAvailableLabel('NON DISPONIBILE');
                            break;
                        case 'en-GB':
                            setAvailableLabel('OUT OF STOCK');
                            break;
                        case 'en-US':
                            setAvailableLabel('OUT OF STOCK');
                            break;
                        case 'de':
                            setAvailableLabel('NICHT VERFÜGBAR');
                            break;
                        case 'fr':
                            setAvailableLabel('NON DISPONIBLE');
                            break;
                        default:
                            setAvailableLabel('OUT OF STOCK');
                            break;
                    }
                }
            })
            .catch(err => {
                console.error(err);
            });
        return;
    };

    React.useEffect(() => {
        void _isAvailable();
    }, [product]);

    /**
     * Gets the react component for product rating.
     * @param  productCardimageSettings - Module image settings for product card.
     * @param  gridSettings - Grid settings defined in theme.
     * @param  imageUrl - Image url.
     * @param fallbackImageUrl - Fallback url for imge.
     * @param  altText - Image Alt text.
     * @param  requestContext - Request context using the component.
     * @returns React component for product image.
     */
    function renderProductPlacementImage(
        productCardimageSettings?: IImageSettings,
        gridSettings?: IGridSettings,
        imageUrl?: string,
        fallbackImageUrl?: string,
        altText?: string,
        requestContext?: IRequestContext
    ): JSX.Element | null {
        if (!imageUrl || !gridSettings || !productCardimageSettings) {
            return null;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Site level config can be of any type.
        const emptyPlaceHolderImage = context.app.config.placeholderImageName as string;
        let fallbackImageSource = fallbackImageUrl;
        if (emptyPlaceHolderImage && fallbackImageUrl) {
            fallbackImageSource = `${fallbackImageUrl},${emptyPlaceHolderImage}`;
        }
        const image: IImageData = {
            src: imageUrl,
            altText: altText ? altText : '',
            fallBackSrc: fallbackImageSource
        };
        const imageProps: IImageProps = { gridSettings };

        imageProps.gridSettings = gridSettings;
        imageProps.imageSettings = productCardimageSettings;
        imageProps.imageSettings.cropFocalRegion = true;
        return <Image {...image} {...imageProps} loadFailureBehavior='empty' requestContext={requestContext} bypassHideOnFailure />;
    }

    if (catPage === 'pick-mix') {
        // @ts-ignore
        const searchContainerResaurces = context?.actionContext?.requestCache?.internalCache?.__MR['SEARCH-RESULT-CONTAINER']?.item;
        // @ts-ignore
        const pmProducts = context.pmProducts;
        const RecordId = product.RecordId;
        const initialQuntity = pmProducts && pmProducts[RecordId] && pmProducts[RecordId].Qty;
        const [add, setAdd] = React.useState(initialQuntity > 0 && initialQuntity !== undefined ? false : true);

        const [quantity, setQuantity] = React.useState(initialQuntity > 0 && initialQuntity !== undefined ? initialQuntity : 0);

        const removedFromCart = () => {
            const localPm = localStorage && localStorage.getItem('pm_products');
            const localPmProds = localPm && localPm !== null ? localPm : JSON.stringify({});
            const pmProductsList = JSON.parse(localPmProds);
            if (pmProductsList[product.RecordId] === undefined) {
                setQuantity(0);
                setAdd(true);
            }
        };
        const cookiePMset = typeof window !== 'undefined' ? localStorage.setItem('pm_products', JSON.stringify(pmProducts)) : undefined;
        React.useEffect(() => {
            const wrapper = document.getElementsByClassName('pm-cart--ctn')[0];

            // tslint:disable-next-line:typedef no-any
            function handleButtonClick(event: any) {
                const isButton = event.target.nodeName === 'BUTTON';

                if (!isButton) {
                    return;
                }
                const timer = setTimeout(() => {
                    removedFromCart();
                }, 100);
                return () => {
                    clearTimeout(timer);
                };
            }

            wrapper.addEventListener('click', handleButtonClick);
            return () => wrapper.removeEventListener('click', handleButtonClick);
        });

        function plusOneProductToPmCart(pRecordId: number): void {
            // @ts-ignore
            const pmProductsList = context.pmProducts;
            const productQuantity = Number(pmProductsList[pRecordId]?.Qty) + 1;
            if (pmProductsList[pRecordId] !== undefined) {
                pmProductsList[pRecordId].Qty = productQuantity;
                cookiePMset;
                setQuantity(productQuantity);
            } else {
                return;
            }
        }

        function removeOneProductFromPmCart(pRecordId: number): void {
            // @ts-ignore
            const pmProductsList = context.pmProducts;
            const productQuantity = Number(pmProductsList[pRecordId]?.Qty) - 1;

            if (productQuantity >= 0) {
                pmProductsList[pRecordId].Qty = productQuantity;
                cookiePMset;
                setQuantity(productQuantity);
            }
            if (productQuantity === 0) {
                delete pmProductsList[pRecordId];
                cookiePMset;
                setAdd(true);
            }
        }

        function addProductToPmCart(
            pRecordId: number,
            Name: string | undefined,
            Description: string | undefined,
            PrimaryImageUrl: string | undefined,
            pQuantity: number,
            Price: number
        ): void {
            // @ts-ignore
            const pmProductsList = context.pmProducts;
            setAdd(false);
            pmProductsList[pRecordId] = {
                Id: pRecordId,
                Item: Name,
                Qty: pQuantity,
                UOM: '',
                Price: Price,
                Description: Description,
                DataAreaId: '',
                PrimaryImageUrl: PrimaryImageUrl
            };
            // @ts-ignore
            context.pmProducts = pmProductsList;
            cookiePMset;
            setQuantity(1);
        }

        // @ts-ignore
        const AddToCartButtons = ({ showadd }) => {
            if (showadd) {
                return (
                    <>
                        {/* tslint:disable-next-line:jsx-no-lambda react-this-binding-issue */}
                        <button
                            className={'border-button add-to-mix-cart'}
                            onClick={() =>
                                addProductToPmCart(
                                    product.RecordId,
                                    product.Name,
                                    product.ItemId,
                                    product.PrimaryImageUrl,
                                    1,
                                    product.Price
                                )
                            }
                        >
                            {searchContainerResaurces.addText}
                        </button>
                    </>
                );
            }

            return (
                <>
                    {/* tslint:disable-next-line:jsx-no-lambda react-this-binding-issue */}
                    <button
                        onClick={() => removeOneProductFromPmCart(product.RecordId)}
                        title='Press to decrease the quantity by 1'
                        className='decrement quantity__controls'
                        aria-hidden='true'
                        aria-label='Press to decrease the quantity by 1'
                        color='secondary'
                    />
                    {/* tslint:disable-next-line:max-line-length */}
                    <input
                        type='number'
                        className='quantity-input'
                        pattern='[0-9]*'
                        value={quantity}
                        aria-live='polite'
                        aria-label='quantity input'
                        role='spinbutton'
                        aria-valuemin={valuemin}
                        aria-valuemax={valuemax}
                        aria-valuenow={quantity}
                        readOnly
                    />
                    {/* tslint:disable-next-line:jsx-no-lambda react-this-binding-issue max-line-length */}
                    <button
                        onClick={() => plusOneProductToPmCart(product.RecordId)}
                        title='Press to increment quantity by 1'
                        className='increment quantity__controls '
                        aria-hidden='true'
                        aria-label='Press to increment quantity by 1'
                        color='secondary'
                    />
                </>
            );
        };
        // @ts-ignore
        return (
            <div className={'msc-product__mix-item'}>
                <div className='msc-product__image'>
                    {renderProductPlacementImage(
                        imageSettings,
                        context.request.gridSettings,
                        product.PrimaryImageUrl,
                        product.PrimaryImageUrl,
                        product.Name,
                        context.actionContext.requestContext
                    )}
                </div>
                <div className='msc-product__details__pm'>
                    <h4 className='msc-product__title'>{product.Name}</h4>
                    {renderModalPopup}
                    {/* tslint:disable-next-line:max-line-length jsx-no-lambda react-this-binding-issue react-a11y-event-has-role */}
                    {
                        <a
                            onClick={() => setModalOpen(true)}
                            aria-label={renderLabel(
                                product.Name,
                                context.cultureFormatter.formatCurrency(product.Price),
                                product.AverageRating,
                                ratingAriaLabel
                            )}
                            className='hyperlink view_details '
                            {...attribute}
                        >
                            {searchContainerResaurces.viewDetailsText}
                        </a>
                    }
                    <p className='msc-product__text'>{product.Description}</p>
                    {renderPrice(
                        context,
                        typeName,
                        id,
                        product.BasePrice,
                        product.Price,
                        savingsText,
                        freePriceText,
                        originalPriceText
                        // currentPriceText
                    )}

                    <div className='quantity'>
                        <AddToCartButtons showadd={add} />
                    </div>
                    <div className='msc-product__extra-data'>
                        <span className='data-product-id'>{product.RecordId}</span>
                        <span className='data-product-sku'>{product.ItemId}</span>
                    </div>
                </div>
            </div>
        );
    }

    // A function that generates a random set of 8 bytes
    //TODO restore way of saving list attribute
    // function generateRandomBytes(): Uint8Array {
    //     const bytes = new Uint8Array(8);
    //     for (let i = 0; i < bytes.length; i++) {
    //         // Generate a random number between 0 and 255
    //         const randomNumber = Math.floor(Math.random() * 256);
    //         // Assign the random number to the byte
    //         bytes[i] = randomNumber;
    //     }
    //     return bytes;
    // }
    // const card_guid = generateRandomBytes().toString();

    function getListAndCategory() {
        const page = getPageTemplate();
        let list_name = '';
        let category_name = '';
        switch (page) {
            case 'Home':
            case 'Cart':
                //TODO restore way of saving list attribute
                // const collections = document.getElementsByClassName('ms-product-collection');
                // Array.from(collections).forEach(collection => {
                //     const cards = collection.getElementsByClassName('ms-product-collection__item');
                //     Array.from(cards).forEach(card => {
                //         if (card.getElementsByClassName('card-guid')[0].textContent === card_guid) {
                //             list_name = collection.getElementsByClassName('ms-product-collection__heading')[0].textContent ?? '';
                //         }
                //     });
                // });
                break;
            case 'Listing':
                // case 'PLP':
                list_name = document.getElementsByClassName('ms-search-result-container__title')[0].childNodes[0].textContent ?? '';
                break;
            case 'Search Results':
                list_name = 'search results';
                break;
            case 'PDP':
                //TODO restore way of saving list attribute
                // list_name = sessionStorage.getItem('list') ?? '';
                list_name = '';
                const output: string[] = [];
                const names = document.getElementsByClassName('ms-breadcrumb_link');
                Array.from(names).forEach(item => {
                    output.push(item.getAttribute('aria-label') ?? '');
                });
                category_name = output.slice(0, -1).toString() ?? '';
                break;
            default:
                list_name = '';
                category_name = '';
                break;
        }
        return [list_name, category_name];
    }

    const _ontoProductPDP = () => {
        //TODO restore way of saving list attribute
        // sessionStorage.setItem('list', getListAndCategory()[0]);

        const [list_name, category_name] = getListAndCategory();
        const productInfo = {
            name: product.Name,
            id: product.ItemId, //product SKU
            price: product.Price.toFixed(2),
            // 'dimension12': product.RecordId, //product ID (microsoft)
            list: list_name,
            category: category_name
        };
        window.dataLayer?.push?.({
            event: 'productClick',
            ecommerce: {
                // @ts-ignore
                currencyCode: window.___initialData___.requestContext.channel.Currency,
                click: {
                    actionField: { list: list_name },
                    products: [productInfo]
                }
            }
        });

        // setTimeout(() => {
        //     window.location.href = productUrl;
        // }, 0);
    };

    const _onAddSelectionToBag = async (event: React.MouseEvent): Promise<void> => {
        event?.stopPropagation();
        event?.preventDefault();
        const [list_name, category_name] = getListAndCategory();
        var productInfo = {
            name: product.Name,
            id: product.ItemId, //product SKU
            price: product.Price.toFixed(2),
            quantity: 1,
            // 'dimension12': product.RecordId, //product ID (microsoft)
            category: category_name,
            list: list_name
        };
        //TODO restore way of saving list attribute
        // localStorage.setItem(product?.ItemId ?? '', category_name + ';' + list_name);

        window.dataLayer?.push?.({
            event: 'addToCart',
            ecommerce: {
                currencyCode: context.request.channel?.Currency,
                add: {
                    actionField: {
                        list: list_name,
                        action: 'add'
                    },
                    products: [productInfo]
                }
            }
        });

        // Also manage the removal from cart?

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- read config file.
        const addToCartBehavior = context.app.config.addToCartBehavior;
        const navigationUrl: string | undefined = getUrlSync('cart', context.actionContext);

        const catalogId = getCatalogId(context.actionContext.requestContext);

        const quantity: number = 1;

        const cartState = await getCartState(context.actionContext);
        const addToCartResult = await cartState.addProductToCart({
            product: data.product as SimpleProduct,
            count: quantity,
            availableQuantity: undefined,
            additionalProperties: undefined,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- read config file.
            enableStockCheck: context.app.config.enableStockCheck,
            isPriceKeyedIn: undefined,
            customPrice: undefined,
            isAddEmailDeliveryItemToCart: undefined,
            shouldSkipSiteSettings: undefined,
            catalogId: catalogId
        });

        if (addToCartResult.status === 'SUCCESS') {
            if (addToCartBehavior === AddToCartBehavior.showModal) {
                // setDisabled(false);
            } else if (addToCartBehavior === AddToCartBehavior.showNotification) {
                // setDisabled(false);
                // const notification = new ItemSuccessfullyAddedToCartNotification(
                //     props.context,
                //     props.dialogStrings,
                //     props.imageSettings,
                //     undefined,
                //     product,
                //     data?.product?.Price,
                //     quantity,
                //     props.navigationUrl,
                //     undefined,
                //     props.id,
                //     props.typeName
                // );
                // NotificationsManager.instance().addNotification(notification);
            } else if (
                MsDyn365.isBrowser &&
                navigationUrl &&
                (addToCartBehavior === undefined || addToCartBehavior === AddToCartBehavior.goToCart)
            ) {
                window.location.assign(navigationUrl);
            } else {
                // setDisabled(false);
            }
            // propagateResult(props, addToCartResult);
        } else {
            // NotificationsManager.instance().addNotification(
            //     new ErrorNotification(
            //         addToCartResult.errorDetails?.LocalizedMessage ?? 'Add to cart failed',
            //         props.dialogStrings?.closeNotificationLabel ?? ''
            //     )
            // );
            // propagateError(props, { failureReason: 'CARTACTIONFAILED', cartActionResult: addToCartResult });
            // setDisabled(false);
        }
    };

    //TODO old
    // const currentCartState = await getCartState(context?.actionContext);
    // const cartLines = [
    //     {
    //         ItemId: product.ItemId,
    //         ProductId: product.RecordId,
    //         Quantity: 1,
    //         TrackingId: ''
    //     }
    // ];
    // return addCartLinesAsync(
    //     { callerContext: context.actionContext },
    //     currentCartState.cart.Id,
    //     cartLines,
    //     currentCartState.cart.Version!
    // ).then(async () => {
    //     await currentCartState.refreshCart({});
    // });

    let cart_string = '';
    switch (context.request.locale) {
        case 'it':
            cart_string = 'Aggiungi';
            break;
        case 'en-GB':
            cart_string = 'Add';
            break;
        case 'en-US':
            cart_string = 'Add';
            break;
        case 'fr':
            cart_string = 'Ajouter';
            break;
        case 'de':
            cart_string = 'In den';
            break;
        default:
            cart_string = 'Add';
            break;
    }

    let percent_discount = '';
    let discount_string = '';

    if (product.Price && product.BasePrice && product.BasePrice !== product.Price) {
        percent_discount = (100 - (product.Price / product.BasePrice) * 100).toFixed(0);
        discount_string = `-${percent_discount}%`;
    }

    const custom_basePrice = context.cultureFormatter.formatCurrency(product.BasePrice!.toFixed(2));
    const custom_adjustedPrice = context.cultureFormatter.formatCurrency(product.Price.toFixed(2));
    const omnibus_price = data.product?.AttributeValues?.filter(attribute => attribute.Name?.includes('Minimum Price Last 30 Days'));
    let omnibus_price_currency = '';
    if (omnibus_price !== undefined && omnibus_price?.length !== 0 && omnibus_price[0] !== undefined) {
        omnibus_price_currency = context.cultureFormatter.formatCurrency(omnibus_price[0].CurrencyValue!, omnibus_price[0].CurrencyCode);
    }

    let omnibus_string: React.ReactNode;
    switch (context.request.locale) {
        case 'it':
            omnibus_string = 'Prezzo più basso recente:';
            break;
        case 'en-GB':
            omnibus_string = 'Lowest recent price:';
            break;
        case 'en-us':
            omnibus_string = 'Lowest recent price:';
            break;
        case 'fr':
            omnibus_string = 'Prix le plus bas récemment:';
            break;
        case 'de':
            omnibus_string = 'niedrigster aktueller Preis:';
            break;
        default:
            omnibus_string = 'Lowest recent price:';
            break;
    }

    return (
        <>
            <a
                href={productUrl}
                onClick={() => {
                    _ontoProductPDP();
                    onTelemetryClick(telemetryContent!, payLoad, product.Name!);
                }}
                aria-label={renderLabel(
                    product.Name,
                    context.cultureFormatter.formatCurrency(product.Price),
                    product.AverageRating,
                    ratingAriaLabel
                )}
                className={`msc-product status_${available ? 'AVAL' : 'OOS'}`}
                {...attribute}
            >
                <div className='msc-product__features'>
                    <div className='msc-product__image'>
                        {renderProductPlacementImage(
                            imageSettings,
                            context.request.gridSettings,
                            product.PrimaryImageUrl,
                            product.PrimaryImageUrl,
                            product.Name,
                            context.actionContext.requestContext
                        )}
                    </div>
                    <div className='msc-product__details container'>
                        <div className='row title-row'>
                            <h4 className='msc-product__title'>{product.Name}</h4>
                        </div>
                        <div className='msc-product__text row'>
                            <p className='msc-product__text'>{product.Description}</p>
                        </div>
                        <div className='msc-product__extra-data'>
                            {/*
                                //TODO restore way of saving list attribute
                                <span className='card-guid'>{card_guid}</span>
                            */}
                            <span className='data-product-id'>{product.RecordId}</span>
                            <span className='data-product-sku'>{product.ItemId}</span>
                        </div>
                        {/* {!context.app.config.hideRating && (
                                renderRating(context, typeName, id, product.AverageRating, product.TotalRatings, ratingAriaLabel)
                            )} */}
                        {product.BasePrice !== product.Price && (
                            <span className='msc-price-discounted'>
                                <span className='msc-price-discounted-mobile'>
                                    <span className='msc-price-discounted__strikethrough'>{custom_basePrice}</span>
                                    <span className='msc-price-discounted__percent-discount'>{discount_string}</span>
                                </span>
                            </span>
                        )}
                    </div>
                </div>
                {product.BasePrice !== 0 ? (
                    <div className='msc-product__aligned-price'>
                        {product.BasePrice === product.Price ? (
                            <>
                                <span className='msc-price'>
                                    <span className='msc-price__actual'>{custom_basePrice}</span>
                                </span>

                                {/* THE FOLLOWING IS SHOWN FOR SPACING REASONS */}
                                <span className='msc-price-discounted__recent-price'>&nbsp;</span>
                                {/* DO NOT REMOVE THIS */}
                            </>
                        ) : (
                            <>
                                <span className='msc-price-discounted'>
                                    <span className='msc-price-discounted__actual-post-discount'>{custom_adjustedPrice}</span>
                                </span>
                                <span className='msc-price-discounted__recent-price'>
                                    {omnibus_price_currency ? (
                                        <>
                                            {omnibus_string}&nbsp;
                                            <span style={{ textDecoration: 'line-through' }}>{omnibus_price_currency}</span>
                                        </>
                                    ) : (
                                        <>&nbsp;</>
                                    )}
                                </span>
                            </>
                        )}
                        {/* <div className='msc-product__min-height-avail-row'> */}
                        <div className={`${!available ? 'ms-buybox__inventory-info' : ''} msc-product__avail-row`}>
                            {available ? (
                                <button
                                    className='msc-add-to-cart'
                                    onClick={_onAddSelectionToBag}
                                    style={{ visibility: loading ? 'hidden' : 'visible' }}
                                >
                                    {/* <button className='solid-button msc-add-to-cart' onClick={_onAddSelectionToBag} style={{ visibility: loading ? 'hidden' : 'visible'}}> */}
                                    {cart_string}
                                </button>
                            ) : (
                                <span className='ms-buybox__inventory-label ms-buybox__inventory-code-oos'>{availableLabel}</span>
                            )}
                        </div>
                        {/* </div> */}
                    </div>
                ) : null}
            </a>
        </>
    );
};

function renderLabel(name?: string, price?: string, rating?: number, ratingAriaLabel?: string): string {
    name = name || '';
    price = price || '';
    return `${name} ${price} ${getRatingAriaLabel(rating, ratingAriaLabel)}`;
}

function getRatingAriaLabel(rating?: number, ratingAriaLabel?: string): string {
    if (rating && ratingAriaLabel) {
        const roundedRating = rating.toFixed(2);
        return format(ratingAriaLabel || '', roundedRating, '5');
    }
    return '';
}

/**
 * Updates the product url link to product details page.
 * @param  productDetailsPageUrl - Product page url.
 * @param  coreContext - Context of the module using the component.
 * @param  queryString - Querystring to be added to the URL.
 * @returns The update product page url.
 */
function updateProductUrl(productDetailsPageUrl: string, coreContext: ICoreContext, queryString: string): string {
    const sourceUrl = new URL(productDetailsPageUrl, coreContext.request.apiSettings.baseUrl);
    if (sourceUrl.search) {
        sourceUrl.search += `&${queryString}`;
    } else {
        sourceUrl.search += queryString;
    }

    const updatedUrl = new URL(sourceUrl.href);
    return updatedUrl.pathname + sourceUrl.search;
}

// function renderRating(
//     context: ICoreContext,
//     typeName: string,
//     id: string,
//     avgRating?: number,
//     totalRatings?: number,
//     ariaLabel?: string
// ): JSX.Element | null {
//     if (!avgRating) {
//         return null;
//     }

//     const numRatings = (totalRatings && totalRatings.toString()) || undefined;
//     const ratingAriaLabel = getRatingAriaLabel(avgRating, ariaLabel);

//     return (
//         <RatingComponent
//             context={context}
//             id={id}
//             typeName={typeName}
//             avgRating={avgRating}
//             ratingCount={numRatings}
//             readOnly={true}
//             ariaLabel={ratingAriaLabel}
//             data={{}}
//         />
//     );
// }

function renderPrice(
    context: ICoreContext,
    typeName: string,
    id: string,
    basePrice?: number,
    adjustedPrice?: number,
    savingsText?: string,
    freePriceText?: string,
    originalPriceText?: string
    // currentPriceText?: string
): JSX.Element | null {
    const price: ProductPrice = {
        BasePrice: basePrice,
        AdjustedPrice: adjustedPrice,
        CustomerContextualPrice: adjustedPrice
    };
    return (
        <>
            <div className='price-row'>
                <PriceComponent
                    context={context}
                    id={id}
                    typeName={typeName}
                    data={{ price: price }}
                    savingsText={savingsText}
                    freePriceText={freePriceText}
                    originalPriceText={originalPriceText}
                />
                {/* {percent_discount !== '' ? (
                    <span className='msc-price__percent-discount'>{discount_string}</span>
                ) : null} */}
            </div>
        </>
    );
}

// @ts-ignore
export default PREVIOUSProductCard;
